.App {
  text-align: center;
}

.App-header {
  background-image: linear-gradient(to bottom,  #080211, #650470);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: white;
}


.top-image {
  min-height: 100px;
  max-height: 200px;
  pointer-events: none;
  margin-top: 0px;
}

.App-logo {
  height: 100px;
  pointer-events: none;
}

.Title {
  text-align: center;
  font-family: Garamond, Arial, Helvetica, sans-serif;
  font-size: 50px;
}

.Text {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin: 5px;
}

.container{
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.container img{
  width: 100px;
  margin-right: 20px;
}

.App-link {
  color: #61dafb;
}

